<template>
	<div class="Loading-ui">
		<div class='contentld'>
			<span class='el-icon-loading'></span>
			<p>{{title?title:'正在提交'}}...</p>
		</div>

	</div>
</template>

<script>
	export default {
		name: '',
		components: {

		},
		props: ['title'],
		data() {
			return {}
		},
		computed: {},
		watch: {},
		created() {},
		destroyed() {},
		beforeDestroy() {},
		mounted() {},
		methods: {

		}
	}
</script>

<style scoped>
	.Loading-ui {
		position: absolute;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;

		background:rgba(116 ,116 ,116 ,0.5);
		z-index: 500;
	}
	.contentld{
		width: 130px;
		height: 130px;
		background: #fff;
		position: absolute;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		margin: auto;
		border-radius:6px;
	}
	.contentld span{
		font-size: 32px;
		text-align: center;
		margin: 0  auto;
		display: block;
		margin-top: 35px;
	}
	.contentld p{
		text-align: center;
		margin-top: 10px;
	}
</style>
